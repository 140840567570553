<template>
    <div class="table-page">
        <el-breadcrumb separator="/" style="margin-bottom: 20px">
            <el-breadcrumb-item :to="{ path: '/main/dashboard' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>微信设置</el-breadcrumb-item>
            <el-breadcrumb-item>二维码</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="page-main">
            <el-row>
                <el-col :lg="6">
                    <el-card class="card">
                        <img src="@/assets/mapp.jpg" class="image">
                        <div class="title">
                            <span>微信小程序</span>
                        </div>
                    </el-card>
                </el-col>
                <el-col :lg="6">
                    <el-card class="card">
                        <img src="@/assets/mp.png" class="image">
                        <div class="title">
                            <span>微信公众号H5</span>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
            }
        },
        methods: {
        },
        mounted() {
        }
    }
</script>

<style lang="scss" scoped>
    .card {
        margin: 20px;
        text-align: center;
        .image {
            width:100%;
            max-width: 300px;
        }
        .title {
            padding: 30px;
        }
    }
</style>
